import React from 'react';
import { useAuthState } from './AuthContext'
import { Welcome } from './Welcome'
import { ApiResult } from './ApiResult'

export const Start = () => {
    const auth = useAuthState();
    if (auth.authenticated) {
        return (
            <div>
                <p>Welcome {auth.name}</p>
                <ApiResult />
            </div>
        )
    }
    else {
        return <Welcome />
    }
}
