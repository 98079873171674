import React from 'react';
import Button from '@material-ui/core/Button';
import { useAuthState, serverGet } from './AuthContext'

export const ApiResult = () => {
    const auth = useAuthState();

    const handleClick = () => {
        serverGet(auth)
    }
    return (
        <div>
            <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Call API
            </Button>            
        </div>        
    )
}
