import React from 'react';

export const Welcome = () =>
{
    return (
        <div>
            <h1>Welcome to garagedocs</h1>
            <p></p>
        </div>
    )
}
