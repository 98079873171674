import * as React from 'react';
import { msalConfig } from './authConfig';
import * as msal from '@azure/msal-browser';
import { b2cPolicies } from './policies';
import { loginRequest, tokenRequest } from './authConfig';
import { apiConfig } from './apiConfig'

type State = { processing: boolean, authenticated: boolean; userName: string; userId: string, name: string, emailAddress: string }
type LoginResult = { userName: string, userId: string, name: string, emailAddress: string }
type ActionType = 'login' | 'logout' | 'editProfile' | 'processingStart' | 'processingEnd'
type SimpleAction = { type: ActionType }
type LoginAction = { type: ActionType, result: LoginResult }
type Action = SimpleAction | LoginAction
type Dispatch = (action: Action) => void
type AuthProviderProps = { children: React.ReactNode }

const msalApp = new msal.PublicClientApplication(msalConfig);

const AuthStateContext = React.createContext<State | undefined>(undefined);

const AuthDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function signIn(dispatch: Dispatch) {

    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    const handleResponse = (response: msal.AuthenticationResult) => {
        /**
         * To see the full list of response object properties, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
         */

        if (response !== null) {
            console.log('handleResponse: userName=' + response.account!.name)
            const tokens: any = response.idTokenClaims
            dispatch({ type: 'login', result: { userName: response.account!.username!, userId: response.account!.homeAccountId, name: response.account!.name!, emailAddress: tokens.emails[0] } })
        } else {
            // TODO: What to do here?
            console.log('handleResponse: response is null')
        }
    }

    const handlePolicyChange = (response: any) => {
        /**
         * We need to reject id tokens that were not issued with the default sign-in policy.
         * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (response.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
            window.alert("Profile has been updated successfully. \nPlease sign-in again.");
            console.log("Profile has been updated successfully.");
            msalApp.logout();
        } else if (response.idTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
            window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
            console.log("Password has been reset successfully. \nPlease sign-in with your new password.");
            msalApp.logout();
        }
        else {
            console.log("Unhandled response");
        }
    }

    dispatch({ type: 'processingStart' });

    msalApp.loginPopup(loginRequest)
        .then(handleResponse)
        .catch(error => {
            console.log(error);
            dispatch({ type: 'processingEnd' })

            // Error handling
            if (error.errorMessage) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (error.errorMessage.indexOf("AADB2C90118") > -1) {
                    msalApp.loginPopup(b2cPolicies.authorities.forgotPassword)
                        .then(response => handlePolicyChange(response));
                }
            }
        });
}

function signOut(dispatch: Dispatch, state: State) {

    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    // Choose which account to logout from.
    const logoutRequest: msal.EndSessionRequest = {
        account: msalApp.getAccountByHomeId(state.userId)!
    };

    const handleResponse = (response: any) => {
        dispatch({ type: 'logout' });
    };

    dispatch({ type: 'processingStart' });
    msalApp.logout(logoutRequest).then(response => handleResponse(response));
}

function callApi(endpoint: string, token: string) {

    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options: RequestInit = {
        method: "GET",
        headers: headers,
        mode: 'cors',
    };

    console.log('Calling Web API...');

    return fetch(endpoint, options)
        .then(response => response.json())
        .then(response => {
            if (response) {
                console.log('Web API responded: ' + response.text)
                alert(response.text)
            }
            return response
        }).catch(error => {
            console.error(error);
        });
}

function serverGet(state: State) {
    const getTokenPopup = (request: msal.PopupRequest) => {
        console.log('calling acquireTokenPopup')
        request.loginHint = state.userName
        return msalApp.acquireTokenPopup(request)
            .then(response => {
                console.log(response);
                return response;
            }).catch(error => {
                console.log(error);
            });
    }

    const getTokenSilent = (request: msal.SilentRequest) => {
        return msalApp.acquireTokenSilent(request)
            .then((response) => {
                // In case the response from B2C server has an empty accessToken field
                // throw an error to initiate token acquisition
                if (!response.accessToken || response.accessToken === "") {
                    throw new msal.InteractionRequiredAuthError();
                }
                return response;
            })
            .catch(error => {
                console.log("silent token acquisition fails. acquiring token using popup");
                if (error instanceof msal.InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return getTokenPopup(request)
                } else {
                    console.log(error);
                }
            });
    }

    /**
    * See here for more information on account retrieval: 
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
    */
    var request: any = tokenRequest
    request.account = msalApp.getAccountByHomeId(state.userId)!;
    getTokenSilent(request)
        .then(response => {
            if (response) {
                console.log("access_token acquired at: " + new Date().toString());
                try {
                    return callApi(apiConfig.webApi, response.accessToken);
                } catch (error) {
                    console.log(error);
                }
            }
        });
}

function authReducer(state: State, action: Action) {
    switch (action.type) {
        case 'login': {
            console.log('authReducer: login')
            const loginAction = action as LoginAction
            return { ...state, processing: false, authenticated: true, userName: loginAction.result.userName, userId: loginAction.result.userId, name: loginAction.result.name, emailAddress: loginAction.result.emailAddress }
        }
        case 'logout': {
            console.log('authReducer: logout')
            return { ...state, processing: false, authenticated: false, userName: '', userId: '' }
        }
        case 'editProfile': {
            // TODO: Edit profile
            console.log('authReducer: editProfile')
            return state
        }
        case 'processingStart': {
            return { ...state, processing: true };
        }
        case 'processingEnd': {
            return { ...state, processing: false };
        }
    }
}

function initAuth() {
    const accounts = msalApp.getAllAccounts();
    if (accounts.length === 1) {
        const tokenClaims: any = accounts[0].idTokenClaims
        const emailAddress: string = tokenClaims.emails[0]
        return {
            processing: false,
            authenticated: true,
            userName: accounts[0].username!,
            userId: accounts[0].homeAccountId,
            name: accounts[0].name!,
            emailAddress: emailAddress,
        }
    }

    if (accounts.length > 1) {
        // TODO: How to select account ?
    }

    return {
        processing: false,
        authenticated: false,
        userName: '',
        userId: '',
        name: '',
        emailAddress: '',
    }
}

function AuthProvider({ children }: AuthProviderProps) {
    const [state, dispatch] = React.useReducer(authReducer, initAuth());

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}

function useAuthState() {
    const context = React.useContext(AuthStateContext)
    if (context === undefined) {
        throw new Error('useAuthState must be used with a AuthProvider')
    }
    return context
}

function useAuthDispatch() {
    const context = React.useContext(AuthDispatchContext)
    if (context === undefined) {
        throw new Error('useAuthDispatch must be used with a AuthProvider')
    }
    return context
}
export { AuthProvider, useAuthState, useAuthDispatch, signIn, signOut, serverGet }
