import React from 'react';
import logo from './logo.svg';
import './App.css';
import TopMenuBar from './TopMenuBar'
import './AuthContext'
import { AuthProvider } from './AuthContext'
import { Start } from './Start'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <TopMenuBar />
        <Start />
      </AuthProvider>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to Garagedocs version 0.0.4
        </p>
        <p>Redirect URI: {process.env.REACT_APP_AUTH_REDIRECT_URI}</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
