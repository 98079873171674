import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useAuthState, useAuthDispatch, signIn, signOut } from './AuthContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
    }),
);


export const AuthButton = () => {
    const classes = useStyles();
    const auth = useAuthState();
    const dispatch = useAuthDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        setAnchorEl(null);
        dispatch({ type: 'editProfile' })
    };

    const handleLogout = () => {
        setAnchorEl(null);
        signOut(dispatch, auth);
    };

    if (auth.processing) {
        return (
            <div className={classes.root}>
                <CircularProgress color="inherit" />
            </div>
        )
    }

    if (auth.authenticated) {
        return (
            <div>
                <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <AccountCircleIcon />&nbsp;
                    {auth.userName}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </div>)
    }
    else {
        return <Button color="inherit" onClick={() => { signIn(dispatch); }}>Login</Button>
    }
}

